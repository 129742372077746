<template>
  <div class="analysis-content">
    <div class="top-bar">
      <div class="right-bar"></div>
      <div class="left-bar"></div>
      <div class="return-btn">
        <span @click="$router.back(-1)" class="return-text">
          <i class="v-icon-back return-icon"></i>
          返回上一级
        </span>
      </div>
    </div>

    <div class="content-analysis">
      <div class="analysis-left-content">
        <div class="equipment-info">
          <div style="color: #89ffe5; font-size: 18px; margin: 10px 31%">
            设备信息
          </div>
          <div class="display-flex">
            <div style="flex: 1">
              <div>型号：型号1</div>
              <div>交流电压：220V</div>
              <div>厂商：厂商1</div>
              <div>制造日期：2024-08-05</div>
            </div>
            <div style="flex: 1">
              <div>型号：型号1</div>
              <div>交流电压：220V</div>
              <div>厂商：厂商1</div>
              <div>投运日期：2024-08-05</div>
            </div>
          </div>
        </div>
        <div class="equipment-diagnosis">
          <dv-border-box-7>
            <div class="title">数据分析</div>
            <div class="diagnosis-items">
              <div class="item">
                <div class="item-title">状态预警</div>
                <div class="item-content">状态预警信息</div>
              </div>
              <div class="item">
                <div class="item-title" style="background-color: #4348d0">
                  故障诊断
                </div>
                <div class="item-content">故障诊断结果</div>
              </div>
              <div class="item">
                <div class="item-title" style="background-color: #d54b50">
                  运维建议
                </div>
                <div class="item-content">运维建议</div>
              </div>
            </div>
          </dv-border-box-7>
        </div>
        <div class="diagnosis-suggest">
          <!-- <div> -->
          <dv-border-box-7>
            <div style="padding: 16px">
              <div class="title">横向分析</div>
              <div class="item-content">
                <div>此设备放电水平略高于其他设备</div>
                <div>此设备温度整体居中</div>
                <div>此设备振动频率正常</div>
              </div>
            </div>
          </dv-border-box-7>
          <dv-border-box-7>
            <div style="margin-top: 10px; padding: 16px">
              <div class="title">纵向分析</div>
              <div class="item-content">
                <div>此设备历史放电水平存在不平稳情况，已预警</div>
                <div>此设备历史温度水平存在不平稳情况，已预警</div>
                <div>此设备历史振动频率相对稳定</div>
              </div>
            </div>
          </dv-border-box-7>

          <!-- </div> -->
        </div>
      </div>
      <div class="analysis-right-content">
        <div class="top-chart">
          <div class="left-chart">
            <div class="prpd-content">
              <dv-border-box-2>
                <div class="chart-font">局部放电prpd图谱</div>
                <div style="margin-top: 10px">
                  <span class="textContent">相位校正</span
                  ><el-input-number
                    class="inputNumber"
                    v-model="num"
                    @change="handleChange"
                    :min="-180"
                    :max="180"
                    label="描述文字"
                  ></el-input-number>
                  <Chart :cdata="cdata" />
                </div>
              </dv-border-box-2>
            </div>

            <div class="middle-table">
              <dv-border-box-12>
                <div class="table-content">
                  <div style="display: flex; justify-content: space-between">
                    <div class="chart-font">
                      空间分布分析<span style="color: orange"
                        >（共XXX个传感器）</span
                      >
                    </div>
                    <div
                      style="
                        margin: 10px 10px -20px 0px;
                        font-size: 14px;
                        line-height: 20px;
                        color: #89ffe5;
                        cursor: pointer;
                      "
                      @click="clickEvent"
                    >
                      点击查看现场对应图
                    </div>
                  </div>
                  <div style="margin-top: 5px">
                    <el-table
                      class="table-layout"
                      border
                      header-align="center"
                      :data="tableData"
                      height="100px"
                      style="width: 90%; margin: 0 auto"
                    >
                      <el-table-column prop="ps1" label="位置1">
                      </el-table-column>
                      <el-table-column prop="ps2" label="位置2">
                      </el-table-column>
                      <el-table-column prop="ps3" label="位置3">
                      </el-table-column>
                      <el-table-column prop="ps4" label="位置4">
                      </el-table-column>
                      <el-table-column prop="ps5" label="位置5">
                      </el-table-column>
                      <el-table-column prop="ps6" label="位置6">
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </dv-border-box-12>
            </div>
          </div>
          <div class="temperature-content">
            <dv-border-box-11 title="温度-热分布模型" style="fontSize: 18px">
              <div class="down">
                <div class="project-manage">
                  <el-image
                    style="width: 90%; height: 90%; margin: 9% 0 0 5%"
                    :src="url"
                    fit="cover"
                  ></el-image>
                </div>
              </div>
            </dv-border-box-11>
          </div>
        </div>
        <div class="bottom-chart">
          <div class="prpd-content">
            <dv-border-box-2>
              <div class="chart-font">横向分析</div>
              <div style="margin-top: 1px">
                <hx :cdata="cdata2" :id="'hx_1'" />
              </div>
              <div style="margin-top: 1px">
                <hx :cdata="cdata1" :id="'hx_2'" />
              </div>
              <div style="margin-top: 1px">
                <hx :cdata="cdata2" :id="'hx_3'" />
              </div>
            </dv-border-box-2>
          </div>

          <div class="temperature-content">
            <dv-border-box-2>
              <div class="chart-font">纵向分析</div>
              <div>
                <div style="margin-top: 1px">
                  <hx :cdata="cdata_zx1" :id="'zx_1'" />
                </div>
                <div style="margin-top: 1px">
                  <hx :cdata="cdata_zx2" :id="'zx_2'" />
                </div>
                <div style="margin-top: 1px">
                  <hx :cdata="cdata_zx3" :id="'zx_3'" />
                </div>
              </div>
            </dv-border-box-2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "./echarts.vue"; // 导入prpd组件
import hx from "./hxecharts.vue"; // 导入prpd组件
import Echart from "@/common/echart"; //导入echarts组件
import { singlePrpds } from "@/api/index";
export default {
  props: {},
  components: {
    Chart,
    Echart,
    hx,
  },
  data() {
    return {
      url: require("../image/reli.png"),
      cdata: {},
      cdata1: {},
      cdata2: {},
      cdata_zx1: {
        grid: {
          show: true,
          left: "10%",
          top: "20%",
          right: "10%",
          bottom: "20%",
          backgroundColor: "rgba(255, 255, 67, 0.1)",
          borderColor: "rgba(96, 67, 67, 1)",
        },
        legend: {
          data: ["参数1", "参数2", "参数3"], //图例名称
          right: 10, //调整图例位置
          top: 0, //调整图例位置
          icon: "line", //图例前面的图标形状
          textStyle: {
            //图例文字的样式
            color: "#a1a1a1", //图例文字颜色
            fontSize: 12, //图例文字大小
          },
        },
        title: {
          top: 0,
          left: "center", // left的值为'left', 'center', 'right'
          text: `✯振动参数`,
          // 标题文本样式
          textStyle: {
            color: "#ff0000", // 颜色
            fontStyle: "normal", // 字体风格,'normal','italic','oblique'
            fontWeight: "700", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
            fontFamily: "sans-serif", //字体系列
            fontSize: 14, //字体大小
          },
        },
        xAxis: {
          type: "category",
          data: ["8-10", "8-11", "8-12", "8-13", "8-14", "8-15", "8-16"],
        },
        yAxis: {
          type: "value",
          name: "（振幅）",
          min: "dataMin", // 设置y轴的最小值为数据最小值
          max: "dataMax", // 设置y轴的最大值为数据最大值
          interval: 1000,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#ff0",
            },
          },
        },
        series: [
          {
            name: "参数1",
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            smooth: true,
          },
          {
            name: "参数2",
            data: [420, 232, 941, 734, 1090, 1630, 1120],
            type: "line",
            smooth: true,
          },
          {
            name: "参数3",
            data: [320, 832, 961, 534, 1190, 1430, 1620],
            type: "line",
            smooth: true,
          },
        ],
      },
      cdata_zx2: {
        grid: {
          show: true,
          left: "10%",
          top: "20%",
          right: "10%",
          bottom: "20%",
          backgroundColor: "rgba(255, 255, 67, 0.1)",
          borderColor: "rgba(96, 67, 67, 1)",
        },
        legend: {
          data: ["A相", "B相", "C相"], //图例名称
          right: 10, //调整图例位置
          top: 0, //调整图例位置
          icon: "line", //图例前面的图标形状
          textStyle: {
            //图例文字的样式
            color: "#a1a1a1", //图例文字颜色
            fontSize: 12, //图例文字大小
          },
        },
        title: {
          top: 0,
          left: "center", // left的值为'left', 'center', 'right'
          text: `✯温度参数`,
          // 标题文本样式
          textStyle: {
            color: "#ff0000", // 颜色
            fontStyle: "normal", // 字体风格,'normal','italic','oblique'
            fontWeight: "700", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
            fontFamily: "sans-serif", //字体系列
            fontSize: 14, //字体大小
          },
        },
        xAxis: {
          type: "category",
          data: ["8-10", "8-11", "8-12", "8-13", "8-14", "8-15", "8-16"],
        },
        yAxis: {
          type: "value",
          name: "（℃）",
          min: "dataMin", // 设置y轴的最小值为数据最小值
          max: "dataMax", // 设置y轴的最大值为数据最大值
          interval: 1000,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#ff0",
            },
          },
        },
        series: [
          {
            name: "A相",
            data: [20, 32, 30, 24, 18, 20, 22],
            type: "line",
            smooth: true,
          },
          {
            name: "B相",
            data: [18, 22, 24, 23, 20, 23, 20],
            type: "line",
            smooth: true,
          },
          {
            name: "C相",
            data: [21, 22, 21, 24, 29, 24, 26],
            type: "line",
            smooth: true,
          },
        ],
      },
      cdata_zx3: {
        grid: {
          show: true,
          left: "10%",
          top: "20%",
          right: "10%",
          bottom: "20%",
          backgroundColor: "rgba(255, 255, 67, 0.1)",
          borderColor: "rgba(96, 67, 67, 1)",
        },
        legend: {
          data: ["最大放电量幅值", "平均放电量幅值", "脉冲数"], //图例名称
          right: 10, //调整图例位置
          top: 0, //调整图例位置
          icon: "line", //图例前面的图标形状
          textStyle: {
            //图例文字的样式
            color: "#a1a1a1", //图例文字颜色
            fontSize: 12, //图例文字大小
          },
        },
        title: {
          top: 0,
          left: "center", // left的值为'left', 'center', 'right'
          text: `✯局放参数`,
          // 标题文本样式
          textStyle: {
            color: "#ff0000", // 颜色
            fontStyle: "normal", // 字体风格,'normal','italic','oblique'
            fontWeight: "700", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
            fontFamily: "sans-serif", //字体系列
            fontSize: 14, //字体大小
          },
        },
        xAxis: {
          type: "category",
          data: ["8-10", "8-11", "8-12", "8-13", "8-14", "8-15", "8-16"],
        },
        yAxis: {
          type: "value",
          name: "（dB）",
          min: "dataMin", // 设置y轴的最小值为数据最小值
          max: "dataMax", // 设置y轴的最大值为数据最大值
          interval: 1000,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#ff0",
            },
          },
        },
        series: [
          {
            name: "最大放电量幅值",
            data: [20, 11, 16, 18, 18, 18, 20],
            type: "line",
            smooth: true,
          },
          {
            name: "平均放电量幅值",
            data: [5, 13, 14, 20, 11, 13, 12],
            type: "line",
            smooth: true,
          },
          {
            name: "脉冲数",
            data: [20, 12, 11, 13, 11, 19, 16],
            type: "line",
            smooth: true,
          },
        ],
      },
      tableData: [
        {
          ps1: "hfct-001",
          ps2: "温度-001",
          ps3: "振动-001",
          ps4: "振动-002",
          ps5: "温度-002",
          ps6: "温度-003",
        },
      ],
      num: 1,
      /* data properties */
    };
  },
  methods: {
    /* methods */
    init(query) {
      // API_V1.0/sensor/prpd_orientation/1/100  <sensor_id>/<偏移量>
      singlePrpds(query)
        .then((data) => {
          let series = [];
          // 正弦曲线
          const xAxisData = [];
          const dataSin = [];
          // 当页面初始化需要展示正弦的时候就使用 ω 不需要展示则直接使用 Max
          // Max这里表示最大值
          const MAX = data.data.max_amplitude;
          // const ω = 0.03;
          const ω = MAX;
          for (let i = 0; i < 360; i += 1) {
            xAxisData.push(i);
            // y=Asin(ωx+φ) + k
            // dataSin.push([i, MAX * Math.sin((2 * Math.PI * i) / 360)]);
            dataSin.push([i, MAX * Math.sin((2 * Math.PI * i) / 360)]);
          }
          //第二步构造数据
          let data1 = []; //数据区域缩放组件
          data1 = data.data.PRPD;
          series = [
            {
              name: "正弦曲线",
              type: "line",
              smooth: true,
              showSymbol: false,
              clip: true,
              data: dataSin,
              lineStyle: {
                color: "#2bf9d6",
                width: 3,
              },
            },
            {
              name: "scatter",
              type: "scatter",
              symbolSize: 4,
              color: "rgba(255,201,14)",
              data: data1,
            },
          ];
          this.cdata = {
            grid: {
              show: true,
              left: "10%",
              top: "20%",
              right: "10%",
              bottom: "20%",
              backgroundColor: "rgba(255, 255, 67, 0.1)",
              borderColor: "rgba(96, 67, 67, 1)",
            },
            title: {
              top: 0,
              left: "center", // left的值为'left', 'center', 'right'
              text: "xxx位置prpd图谱",
              // 标题文本样式
              textStyle: {
                color: "#fff", // 颜色
                fontStyle: "normal", // 字体风格,'normal','italic','oblique'
                fontWeight: "200", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
                fontFamily: "sans-serif", //字体系列
                fontSize: 14, //字体大小
              },
            },
            xAxis: {
              type: "category",
              minorTick: {
                show: false,
              },
              minorSplitLine: {
                show: false,
              },
              axisLabel: {
                align: "center",
              },
              data: xAxisData,
            },
            yAxis: {
              name: "幅值（mV）",
              type: "value",
              interval: 100,
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                crossStyle: {
                  color: "#999",
                },
              },
            },
            series: [
              {
                name: "正弦曲线",
                type: "line",
                smooth: true,
                showSymbol: false,
                clip: true,
                data: dataSin,
                lineStyle: {
                  color: "#2bf9d6",
                  width: 3,
                },
              },
              {
                name: "scatter",
                type: "scatter",
                symbolSize: 4,
                color: "rgba(255,201,14)",
                data: data1,
              },
            ],
          };
          this.cdata1 = {
            grid: {
              show: true,
              left: "10%",
              top: "20%",
              right: "10%",
              bottom: "20%",
              backgroundColor: "rgba(255, 255, 67, 0.1)",
              borderColor: "rgba(96, 67, 67, 1)",
            },
            title: {
              top: 0,
              left: "center", // left的值为'left', 'center', 'right'
              text: "xx位置温度数据横向分析",
              // 标题文本样式
              textStyle: {
                color: "#fff", // 颜色
                fontStyle: "normal", // 字体风格,'normal','italic','oblique'
                fontWeight: "200", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
                fontFamily: "sans-serif", //字体系列
                fontSize: 14, //字体大小
              },
            },
            xAxis: {
              type: "category",
              minorTick: {
                show: false,
              },
              minorSplitLine: {
                show: false,
              },
              axisLabel: {
                align: "center",
              },
              data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            },
            yAxis: {
              name: "（温度）℃",
              type: "value",
              interval: 100,
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                crossStyle: {
                  color: "#999",
                },
              },
            },
            series: [
              {
                data: [
                  120,
                  {
                    value: 200,
                    itemStyle: {
                      color: "#a90000",
                    },
                  },
                  150,
                  80,
                  70,
                  110,
                  130,
                ],
                type: "bar",
              },
            ],
          };
          this.cdata2 = {
            grid: {
              show: true,
              left: "10%",
              top: "20%",
              right: "10%",
              bottom: "20%",
              backgroundColor: "rgba(255, 255, 67, 0.1)",
              borderColor: "rgba(96, 67, 67, 1)",
            },
            title: {
              top: 0,
              left: "center", // left的值为'left', 'center', 'right'
              text: "xx位置局放数据横向分析",
              // 标题文本样式
              textStyle: {
                color: "#fff", // 颜色
                fontStyle: "normal", // 字体风格,'normal','italic','oblique'
                fontWeight: "200", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
                fontFamily: "sans-serif", //字体系列
                fontSize: 14, //字体大小
              },
            },
            xAxis: {
              type: "category",
              minorTick: {
                show: false,
              },
              minorSplitLine: {
                show: false,
              },
              axisLabel: {
                align: "center",
              },
              data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            },
            yAxis: {
              name: "幅值（dB）",
              type: "value",
              interval: 100,
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                crossStyle: {
                  color: "#999",
                },
              },
            },
            series: [
              {
                data: [
                  120,
                  {
                    value: 200,
                    itemStyle: {
                      color: "#a90000",
                    },
                  },
                  150,
                  80,
                  70,
                  110,
                  130,
                ],
                type: "bar",
              },
            ],
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleChange(value) {
      // let query = `${this.sensorChannel.sensor_id}/${this.sensorChannel.channel}/${value}`
      this.init();
    },
    clickEvent() {
      console.log("aaa");
    },
  },
  mounted() {
    /* lifecycle hook */
    // this.equipmentName = this.$route.params.name;
    this.init();
  },
};
</script>

<style lang="scss" scoped>
/* Your styles here */
@import "../../css/detailInfo.scss";
.analysis-content {
  margin: 0 10px;
  height: calc(100vh - 160px);
  overflow-y: scroll;
  color: rgb(184, 171, 171);

  .display-flex {
    display: flex;
  }

  .display-align {
    align-items: center;
  }

  .display-justify {
    justify-content: space-between;
  }

  .content-analysis {
    width: 100%;
    // height: calc(100vh - 200px);
    display: grid;
    grid-template-columns: 2fr 6fr;
    margin-top: 0;

    .analysis-left-content {
      height: 100%;

      .equipment-info {
        background-color: #1373af50;
        width: 100%;
        height: 30%;
        box-sizing: border-box;
        margin: 8px 10px 5px 0px;
        padding: 10px 12px 10px 12px;
        font-size: 16px;

        div {
          line-height: 30px;
          font-weight: 500;
        }
      }

      .equipment-diagnosis {
        color: rgb(229, 231, 231);
        font-size: 16px;
        width: 100%;
        height: 25%;

        .title {
          margin: 10px 30px;
          font-size: 18px;
          color: #89ffe5;
        }
        .diagnosis-items {
          display: flex;
          width: 100%;
          height: 80%;
          justify-content: space-around;
          gap: 10px;

          .item {
            flex: 1;
            text-align: center;
            height: auto;
            border: 1px solid #33383b;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .item-title {
              height: 26px;
              line-height: 26px;
              font-size: 17px;
              background-color: #1c7edc;
            }

            .item-content {
              height: 100%;
              line-height: 30px;
              font-size: 15px;
            }
          }
        }
      }

      .diagnosis-suggest {
        width: 100%;
        margin-top: 10px;
        height: 38;

        .title {
          margin-left: 12px;
          font-size: 18px;
          color: #89ffe5;
        }

        .item-content {
          width: 100%;
          text-align: center;
          height: 90px;
          font-size: 15px;
          margin: 14px 0;

          div {
            line-height: 30px;
          }
        }
      }
    }

    .analysis-right-content {
      height: 100%;

      .top-chart {
        display: flex;
        height: 47%;

        .chart-font {
          height: 26px;
          line-height: 26px;
          color: rgb(103, 255, 255);
          font-size: 17px;
          padding: 5px 10px 5px 10px;
        }
        .left-chart {
          flex: 1;
        }
        .prpd-content {
          width: 100%;
          margin: 8px 10px 5px 10px;
        }

        .temperature-content {
          flex: 1;
          margin: 8px 10px 5px 10px;
          .down {
            background-repeat: no-repeat;
            padding: 0rem 0rem;
            padding-bottom: 0;
            width: 100%;
          }
          .project-manage {
            width: 100%; /* 设置你期望的div宽度 */
            height: 347px; /* 设置你期望的div高度 */
          }
        }
      }

      .bottom-chart {
        display: flex;
        height: 51%;

        .chart-font {
          height: 26px;
          line-height: 26px;
          color: rgb(103, 255, 255);
          font-size: 17px;
          padding: 2px 10px 2px 10px;
        }

        .prpd-content {
          width: 45%;
          margin: 2px 10px 2px 10px;
        }

        .temperature-content {
          width: 53%;
          margin: 8px 10px 5px 10px;
        }
      }
    }
  }
  .textContent {
    color: rgb(164, 252, 252);
    font-size: 14px;
    position: absolute;
    top: 0.2rem;
    z-index: 1;
    left: 8.1rem;
  }
  .inputNumber {
    position: absolute;
    top: 0.1rem;
    left: 5.8rem;
  }
  // 设置输入框的高度
  /deep/ .el-input__inner {
    height: 32px;
    line-height: 32px;
    color: #fff;
    background-color: #85c1fa52;
  }

  /deep/ .el-input-number__increase {
    height: 32px;
    line-height: 32px;
    color: #fff;
    background-color: #85c1fa52;
  }

  /deep/ .el-input-number__increase {
    top: 4px !important;
  }

  /deep/ .el-input-number__decrease {
    height: 32px;
    line-height: 32px;
    color: #fff;
    background-color: #85c1fa52;
  }

  /deep/ .el-table tr {
    background-color: transparent !important;
    text-align: center !important;
  }

  /deep/ .el-table th {
    color: #fff;
    text-align: center;
    background-color: #1f658842 !important;
  }
  /* 隐藏原有的折叠图标 */
  /deep/ .el-table__expand-icon .el-icon svg {
    display: none;
  }

  /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid #555f64;
  }
  /deep/ .el-table .el-table__cell {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  /deep/ .el-table {
    color: #fff;
    background-color: #3a6d9c54;
  }
  /deep/ .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #74cdface;
    background-color: transparent;
  }

  /deep/ .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    content: "";
    position: absolute;
    background-color: #5bb3dfa2;
    z-index: 1;
  }

  /deep/ .el-table--border th,
  .el-table__fixed-right-patch {
    border-bottom: 1px solid #5bb3dfa2;
  }

  /deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(4, 130, 255, 0.87); //颜色必须是rgb
  }
}
</style>
