<template>
  <div>
    <Echart
      :options="options"
      id="bottomLeftChart"
      :height="'200px'"
      :width="'100%'"
    ></Echart>
    <div
      style="position: absolute; bottom: 10px; left: 20px; display: flex;"
    >
      <div style="margin-left: 20px; color: #ff0; font-size: 14px">最大放电幅值：XXX</div>
      <div style="margin-left: 20px; color: #ff0; font-size: 14px">平均放电幅值：XXX</div>
      <div style="margin-left: 20px; color: #ff0; font-size: 14px">脉冲数：6</div>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          title: {
            top: 0,
            left: "center", // left的值为'left', 'center', 'right'
            text: "xxx位置prpd图谱",
            // 标题文本样式
            textStyle: {
              color: "#fff", // 颜色
              fontStyle: "normal", // 字体风格,'normal','italic','oblique'
              fontWeight: "700", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
              fontFamily: "sans-serif", //字体系列
              fontSize: 14, //字体大小
            },
          },
          xAxis: {
            name: "相位",
            min: 0,
            max: 360,
            minorTick: {
              show: false,
            },
            minorSplitLine: {
              show: false,
            },
            axisLabel: {
              align: "center",
              margin: -30, //刻度标签与轴线之间的距离
            },
          },
          yAxis: {
            name: "幅值（mV）",
            min: -4096,
            max: 4096,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
            extraCssText: "z-index: 2",
            formatter: this.formatter
          },
          //图例说明
          legend: {
            top: 30,
            left: 100,
            itemGap: 30,
            itemWidth: 20,
            itemHeight: 10,
          },
          series: newData.series,
        };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    formatter (params){
        let temp=[];
        let temp2='';
        let leftSide='';
        let rightSide='';
        for( let item of params){
            temp.push({
                seriesName:item.seriesName,
                value:item.value
            })
        }
        let arr=temp.sort(this.sortValue);
        let midValue=Math.ceil(arr.length/2);
        for(let [key,item] of arr.entries()){
            if(key< midValue&&midValue>2){
                    leftSide+=`${item.seriesName}: [${item.value}]<br>`;
            }else{
                    rightSide+=`${item.seriesName}: [${item.value}]<br>`;
            }
        }
        temp2=`<div style="display: flex">
            <div>${leftSide}</div>
            <div style="margin-left:20px;">${rightSide}</div>
            </div>`;
        return temp2;
    },    
  }
};
</script>
